<template>
  <div class="d-flex justify-content-center wrapper">
    <VCol cols="6" :style="{ background: bgColor }" v-if="$vuetify.breakpoint.mdAndUp">
      <VRow justify="center" class="align-center max-100-svh flex-direction-column">
        <!-- <Snowf
        :amount="120"
        :size="5"
        :speed="1.2"
        :wind="1"
        :opacity="0.8"
        :swing="2"
        :image="null"
        :zIndex="null"
        :resize="true"
        color="#fff"
      /> -->
        <!-- <img
          :src="require('../../assets/images/logo_green.svg')"
          alt="Logo image"
          class="mx-auto unselectable"
          style="width: 50%"
          draggable="false"
        >
        <span class="main__title">Ваш Онлайн Склад</span> -->
        <v-img :src="require('../../assets/images/gray-bg.jpg')">
          <div class="layout__wrapper">
            <div class="title__wrapper">
              <img
                :src="require('../../assets/images/logo_green.svg')"
                alt="Logo image"
                class="mx-auto unselectable"
                draggable="false"
              />
            </div>
            <div class="img__wrapper">
              <v-img
                :src="require('../../assets/images/rwfy.png')"
                alt="Logo image"
                class="mx-auto unselectable"
                draggable="false"
              >
                <template v-slot:placeholder>
                  <VRow
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    style="min-height:60px"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="30"
                      :width="3"
                      color="white "
                    ></v-progress-circular>
                  </VRow>
                </template>
              </v-img>
            </div>
          </div>
        </v-img>
        <!-- <img
          :src="require('../../assets/images/rwfy_01.jpg')"
          alt="Logo image"
          class="unselectable layout_img"
          draggable="false"
        > -->
      </VRow>
    </VCol>
    <VCol cols="12" lg="6" sm="12" md="6" xs="12" class="main">
      <VRow class="justify-center align-center min-100-vh" no-gutters>
        <VCol cols="8">
          <slot></slot>
        </VCol>
      </VRow>
      <div class="logo-wrapper" v-if="$vuetify.breakpoint.mobile">
        <img
                :src="require('../../assets/images/logo_dark_ua.svg')"
                alt="Logo image"
                class=""
                draggable="false"
                width="150"
              /> 
      </div>
      <div class="auth-language">
        <language :isOutlined="!$vuetify.breakpoint.mobile" />
      </div>
    </VCol>
  </div>
</template>

<script>
import Language from "../layout/Language.vue";

export default {
  name: "AuthLayout",
  components: {
    Language
  },
  computed: {
    bgColor() {
      return this.$vuetify.theme.defaults.light.gray;
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  padding: 0;
  background-image: url("../../assets/images/Frame.svg");
  background-repeat: repeat;
  background-position: center;
  background-size: 70%;
  position: relative;
  height: 100%;
  &__title {
    color: #ffff;
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
.layout__wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  .title__wrapper {
    width: 400px;
    height: 120px;
    background: rgba(59, 53, 53, 0.699);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
      height: 130px;
    }
  }
  .img__wrapper {
    width: 70%;
    padding-bottom: 10px;
    margin: auto;
  }
}
.auth-language{
  position: absolute;
  top: 3%;
  right: 3%;
}
.logo-wrapper{
  position: absolute;
  top: 3%;
  left: 3%;

}
</style>
