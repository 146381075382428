<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from '../components/auth/LoginComponent.vue';

export default {
  name: 'Login',
  components: {
    LoginComponent,
  },
};
</script>

<style >
</style>
