<template>
  <VDialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    @click:outside="closeDialog"
  >
    <VCard>
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20">{{$t('auth.reset_pass')}}</span>
      </VCardTitle>
      <VDivider class="mb-2" />
      <VCardText class="pb-0">
        <VContainer>
          <VRow>
            <VCol cols="12">
              <VTextField
                outlined
                :label="$t('form.email')"
                v-model="user.email"
                dense
                :hide-details="!emailErrors.length"
                :error-messages="emailErrors"
                background-color="white"
                ref="login_email"
              /> </VCol
          ></VRow> </VContainer
      ></VCardText>
      <VCardActions right>
        <VRow class="my-0 py-0 containser">
          <VSpacer />
          <VCol col="12" md="6" sm="12">
            <VBtn  color="success" class="success-bg br-10" @click="onSendEmail">
              <VIcon left>
                mdi-pencil
              </VIcon>
              {{$t('auth.reset_pass')}}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import notifications from '../../mixins/notifications';
import langMixin from '../../mixins/language';
import requestService from '../../services/request/auth/authService';
import emailValidation from '../../validators/email';

export default {
  mixins: [validationMixin, notifications, langMixin],
  data: () => ({
    user: {
      email: '',
    },
  }),
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  validations: {
    user: {
      email: { required, emailValidation },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
      this.$v.$reset();
      this.user = {};
    },
    async onSendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('email', this.user.email);
          formData.append('url_landing', `https://${window.location.host}/password/new/`);
          await requestService.passwordReset(formData);
          this.setSuccessNotification(this.$t('auth.change_pass_sended'));
          this.loading = false;
          this.$emit('close');
        } catch (e) {
          this.loading = false;
          let error = e
          if (e.statusCode == 400) {
            error = this.$t(`auth.${e.error.description.split(':')[0]}`)
          }
          this.setErrorNotification(error);
        }
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.emailValidation && errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
  },
};
</script>

<style></style>
