<template>
  <AuthLayout>
    <VCol>
    <p class="text-h3 text-center mb-3"
      :class="{'text-h6' : $vuetify.breakpoint.xs}" cols="12" >{{ $t('auth.titleLogin') }}</p>
    </VCol>
    <VForm @submit.prevent="onLogin" >
      <VRow no-gutters>
        <VCol cols="12">
          <VTextField
            outlined
            :label="$t('form.email')"
            v-model.trim="user.email"
            dense
            :error-messages="emailErrors"
            background-color="white"
            ref="login_email"
          />
        </VCol>
        <VCol cols="12">
          <VTextField
            outlined
            autocomplete="new-password"
            class="iconPass"
            ref="login_password"
            :label="$t('form.password')"
            dense
             background-color="white"
            v-model.trim="user.password"
            :type="passwordVisible ? 'text' : 'password'"
            :append-icon=" passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordVisible = !passwordVisible"
            :error-messages="passwordErrors"
          />
        </VCol>
        <VCol class="text-center mt-3" cols="12">
          <VBtn
            color="success"
            class="success-bg br-10"
            depressed
            large
            type="submit"
            block
            :loading="loading"
          >
            {{ $t('auth.btnLogin') }}
          </VBtn>
            <VRow justify="space-around" no-gutters class="mt-6">
              <VCol cols="auto">
                <VBtn  color="error" text  @click="showResetDialog">
                  {{ $t('auth.btnForgot') }}
                 </VBtn>
              </VCol>
              <VCol cols="auto">
                <VBtn  color="primary" text  @click="$router.push('/register')">
                  {{ $t('auth.btnToRegister') }}
                </VBtn>
              </VCol>
            </VRow>
        </VCol>
      </VRow>
    </VForm>
    <ResetPasswordDialog :visible="isShowResetDialog" @close="isShowResetDialog = false"/>
  </AuthLayout>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import * as actionTypes from '@/store/modules/auth/types/actions';
import langMixin from '../../mixins/language';
import user from '../../mixins/user';
import AuthLayout from './AuthLayout.vue';
import ResetPasswordDialog from './ResetPasswordDialog.vue';
import notifications from '../../mixins/notifications';
// import { LOG_IN } from '../../store/modules/auth/types/actions';

export default {
  name: 'LoginComponent',
  mixins: [validationMixin, langMixin, notifications,user],
  components: {
    AuthLayout,
    ResetPasswordDialog,
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  data: () => ({
    passwordVisible: false,
    loading: false,
    user: {
      email: '',
      password: '',
    },
    isShowResetDialog: false,
  }),
  mounted() {

  },
  methods: {
    showResetDialog() {
      this.isShowResetDialog = true;
    },
    ...mapActions('auth', {
      logIn: actionTypes.LOG_IN,
      fetchUserData: actionTypes.FETCH_USER_DATA,
    }),

    async onLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('email', this.user.email);
          formData.append('password', this.user.password);
          await this.logIn(formData);
          this.setSuccessNotification(this.$t('auth.loginSuccess'));
          this.loading = false;
          await this.fetchUserData();
          this.$router.push(this.$localize({ name: this.getMainPage }));
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(this.$t('auth.errorLogin'));
        }
      }
    },
  },
  computed: {
    getMainPage(){
      if(this.permissions.crm_user){
        return 'crm'
      }
      return 'cabinet'
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required
        && errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email
        && errors.push(this.$t('form.errors.EmailRequired'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.required
      && errors.push(this.$t('form.errors.PasswordRequired'));
      return errors;
    },
  },
};
</script>

<style lang="scss">

</style>
